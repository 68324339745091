import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../config.json";

const SourceCard = ({ msg, chatMessageId }) => {
    const [imageError, setImageError] = useState(false);
    const [signedUrls, setSignedUrls] = useState({ thumbnail: null });
    const [loading, setLoading] = useState(false);

    const handleImageError = () => {
        setImageError(true);
    };

    const fetchSignedUrl = async (blobPath) => {
        try {
            const response = await axios.get(`${config.serviceUrl}/get-signed-url`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: localStorage.getItem("token"),
                },
                params: {
                    blob_path: blobPath,
                    chat_message_id: chatMessageId
                },
            });
            return response.data.signed_url;
        } catch (error) {
            console.error("Error fetching signed URL for", blobPath, error);
            return null;
        }
    };

    useEffect(() => {
        if (!msg) return;

        const fetchThumbnailUrl = async () => {
            let thumbnailUrl = null;

            const baseMediaUrl = config.baseMediaUrl; 
            const thumbnailBucketPath = config.thumbnailBucketPath;
            const cleanThumbnailPath = msg.thumbnail.replace(baseMediaUrl, "");

            if (msg.type === "document" || msg.type === "webpage" || msg.type === "youtube") {
                thumbnailUrl = await fetchSignedUrl(`${thumbnailBucketPath}/${cleanThumbnailPath}`);
            }

            setSignedUrls((prev) => ({ ...prev, thumbnail: thumbnailUrl }));
        };

        fetchThumbnailUrl();
    }, [msg]);

    const handleDocumentClick = async () => {
        if (msg.type === "document" && msg.filename) {
            try {
                setLoading(true);

                const rawBucketPath = config.rawBucketPath;
                const signedUrl = await fetchSignedUrl(`${rawBucketPath}/${msg.filename}`);
                if (signedUrl) {
                    window.open(signedUrl, "_blank", "noopener,noreferrer");
                }
            } catch (error) {
                console.error("Error fetching document URL:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="card source-card m-2 p-2 me-3">
            <div className="crop">
                {/* Show loading spinner in place of the image */}
                {!signedUrls.thumbnail && !imageError && (
                    <div className="loading-placeholder d-flex justify-content-center align-items-center">
                        <div className="spinner-border text-secondary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}

                {!imageError && msg.type === "document" && signedUrls.thumbnail && (
                    <img
                     
                    src={signedUrls.thumbnail}
                        className="card-img-top border"
                        alt={msg.filename}
                        onError={handleImageError}
                    />
                )}
                {!imageError && msg.type === "youtube" && (
                    <img
                        src={`https://img.youtube.com/vi/${msg.url.split("v=")[1]}/maxresdefault.jpg`}
                        className="card-img-top border"
                        alt={msg.filename}
                        onError={handleImageError}
                    />
                )}
                {!imageError && msg.type === "webpage" && signedUrls.thumbnail && (
                    <img
                        src={signedUrls.thumbnail}
                        className="card-img-top border"
                        alt={msg.filename}
                        onError={handleImageError}
                    />
                )}
                {imageError && <p>Image Unavailable</p>}
            </div>
            <div className="card-body d-flex flex-column">
                {msg.type === "document" && (
                    <p className="card-text mt-auto text-wrap">
                        {msg.filename} <br />
                        Page Number(s): {msg.pages.join(", ")}
                    </p>
                )}
                {msg.type === "youtube" && (
                    <p className="card-text mt-auto text-wrap">
                        {msg.vid_name} <br />
                        Timestamp(s):{" "}
                        {msg.timestamps.map((timestamp, index) => (
                            <span key={index}>
                                {timestamp} ({Math.round(msg.durations[index])} sec){" "}
                                {index < msg.timestamps.length - 1 ? " | " : ""}
                            </span>
                        ))}
                    </p>
                )}
                {msg.type === "webpage" && <p className="card-text">{msg.filename}</p>}


                {msg.type === "document" && (
                    <button
                        className="btn btn-primary mt-auto"
                        onClick={handleDocumentClick}
                        disabled={loading} 
                    >
                        {loading ? "Loading..." : msg.type}{" "}
                        <i className="bi bi-box-arrow-up-right"></i>
                    </button>
                )}

                {msg.type !== "document" && msg.url && (
                    <a
                        href={msg.url}
                        target="_blank"
                        className="btn btn-primary mt-auto"
                        rel="noopener noreferrer"
                    >
                        {msg.type} <i className="bi bi-box-arrow-up-right"></i>
                    </a>
                )}
            </div>
        </div>
    );
};
export default SourceCard;